import React from "react"
import { graphql } from "gatsby"
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"
import { IntlProvider } from "react-intl"
import "intl"
import Layout from "../components/Layout"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import AboutUsContent from "../components/english/about-us/AboutUsContent"
import PageHeader from "../components/english/PageHeader"

const AboutUs = ({ data, location, i18nMessages }) => {
  const url = location.pathname
  const { langs, defaultLangKey } = data.site.siteMetadata.languages
  const langKey = getCurrentLangKey(langs, defaultLangKey, url)
  const homeLink = `/${langKey}/`
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url))
  return (
    <IntlProvider locale={langKey} messages={i18nMessages}>
      <Layout
        seoTitle="About Us"
        seoKeywords="JFE Engineering Indonesia, JFEEI, PT. JFEEI, JFEEI Director, Energy, Environment, Construction, Sustainability, SDG, EPC Company in Indonesia, Best EPC Company in Indonesia, Japanese EPC Company in Indonesia, Japanese quality, JFEEI Quality Policy, JFEEI Health Policy, JFEEI Safety Policy, JFEEI Environmental Policy, JFE Engineering Policy, JFEEI Certifications, JFE Engineering Indonesia Certifications, PT. JFE Engineering Indonesia Certifications"
      >
        <Navbar langKey={langKey} langs={langsMenu} url={url} />
        <PageHeader
          pageTitle="About Us"
          pageDescription={data.markdownRemark.frontmatter.header?.description}
        />
        <AboutUsContent />
        <Footer langKey={langKey} />
      </Layout>
    </IntlProvider>
  )
}

export default AboutUs

export const query = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "about-us" } }) {
      frontmatter {
        header {
          description
        }
      }
    }
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`
